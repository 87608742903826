@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
//@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
//@include foundation-accordion;
//@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
//@include foundation-callout;
//@include foundation-close-button;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include motion-ui-transitions;
@include motion-ui-animations;

img {
  display: inherit;
}
.wrapper {
    margin: 1rem auto;
    padding: 0;
    width: 90%;
    background: $white;
    clear: both;

    @include breakpoint(medium) {
        width: 80%;
        margin: 2rem auto;
        padding: 1rem;

    }
}
.accent-color {
    color:$primary-color!important;
}

a {
    font-weight: $anchor-weight;
}

.text_center {
    text-align: center;
}
header {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 1rem;
    h1 {
        margin-top: 2rem;
        color: $black;
        @include breakpoint(medium down) {
            font-size: 2.5rem;
        }
    }
    h2 {
    	color: $primary-color;
        @include breakpoint(medium down) {
            font-size: 2.25rem;
        }
    }

}

.main {
    padding: 0 4rem 4rem 4rem;
    h3 {
        margin-top: 3rem;
        color: $black;
    }
    h4 {
        margin-top: 2.5rem;
        &.no-margin-top {margin-top:0;}
    }

    @include breakpoint(medium down) {
        padding: 0 2rem 4rem 2rem;
    }
    @include breakpoint(small only) {
        padding: 0 1rem 4rem 1rem;
    }

}

.banff_crest {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
        img {
            width: 25rem;
            margin: 0 auto;
        }
}
figcaption {
    display: initial;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-style: italic;
    width: 25rem
}
.list_style__none {
    list-style: none;
}
footer {
    padding-bottom: 1rem;

    .copyright_text {
        font-size: 80%;
        font-style: italic;
        color: $secondary-color;
    }
}

.clearfix:after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
}
